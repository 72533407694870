
/**
 * Update the consent capture endpoint response for clients like sunlynk & adopt
 *
 * @export
 * @param {array} target -
 * @param {object} data -
 * @param {object} externalClientDetails -
 * @returns {void}
 */
export function updateConsentResponse(target, data, externalClientDetails) {
	externalClientDetails.vendorKey = externalClientDetails?.id;
	externalClientDetails.vendorDisplayName = externalClientDetails?.name;
	externalClientDetails.parentVendorKey = data.vendorKey;
	externalClientDetails.parentVendorName = data.vendorDisplayName;
	target.push(externalClientDetails);
}
