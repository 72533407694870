/**
 * This function grabs the user's interation with the consent capture checkboxes and sends it through the Heap tracking
 */
export function addUserConsentTcpaToHeap() {
	const allProvidersCheckboxes = document.querySelectorAll('#tcpa-copy .consent-capture');
	const providersConsentData = {};
	if (allProvidersCheckboxes) {
		allProvidersCheckboxes.forEach((provider) => {
			providersConsentData[`CheckTCPA[${provider.getAttribute('data-name')}]`] = (provider.checked ? 'yes' : 'no');
		});
	}
	window.modForm.homeownerLeadConversionProps.CheckTCPA = JSON.stringify(providersConsentData);
}