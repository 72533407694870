import { getTcpaElementContent, appendFormInput } from '../../../helpers';

export function updateConsentHiddenFields() {
	if (window.modForm.opts.fetchAndFormatConsentListings || window.modForm.opts.sendConsentCaptureTcpaToLead) {
		const tcpaCopyField = window.modForm.opts.tcpaCopy && window.modForm.opts.tcpaCopy.length ? Array.from(window.modForm.opts.tcpaCopy).map(tcpa => getTcpaElementContent(tcpa).trim().replace(/(<([^>]+)>)/ig, '').replace(/\n/g, '').replace(/[\u00A0]+/g, '')) : '';
		const fieldsToBeUpdated = {
			tcpaText: tcpaCopyField,
			HomePhoneConsentLanguage: tcpaCopyField
		};
		Object.keys(fieldsToBeUpdated).forEach((key) => {
			appendFormInput(key, fieldsToBeUpdated[key]);
		});
	}
}
