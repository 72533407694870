/* globals Promise */
import {
	getFormData,
	getFormsServiceApiDomain,
	mapDynamicServiceCode,
	mapServiceSpecificAttributes, modifyTcpaForExclusiveVendors,
	getAdoptExternalClientNames,
	updateConsentResponse,
	updateConsentHiddenFields
} from '../../helpers';
/**
 * Submit trade-specific answers to HS Forms Service to get Consent Vendor Listings
 * @param {function} callback - Allows us to run a callback function & grab the response
 */
export function getConsentListing(callback) {
	window.modForm.opts.gettingLiveConsent = true;

	const apiDomain = getFormsServiceApiDomain(),
		apiUrl = apiDomain + 'forms/consent-searches',
		providersWrapper = window.modForm.opts.form.querySelector('.tcpa-providers'),
		formData = getFormData(),
		vwo = {};

	let targetDiv = window.modForm.opts.form.querySelector('[data-providers-consent]'),
		isExclusiveMatch = false;

	const lowerCasedQuadLinkData = Object.fromEntries(
		Object.entries(window.modForm.opts.quadLinkParams).map(([key, value]) => [key.toLowerCase(), value])
	);

	const quadLinkData = window.modForm.opts.quadLinkData.quadlink ? { 'quadLink': window.modForm.opts.quadLinkData.quadlink } : lowerCasedQuadLinkData,
		service = mapDynamicServiceCode(formData),
		partnerSourceID = formData.PartnerSourceID ? { partnerSourceID: formData.PartnerSourceID } : {};

	mapServiceSpecificAttributes(service);

	if (window._vwo_campaignData && Object.keys(window._vwo_campaignData).length !== 0) {
		vwo.vwoTestId = Object.keys(window._vwo_campaignData)[0];
		vwo.vwoVariationName = window._vwo_campaignData[vwo.vwoTestId].n;
	}

	const consentSearchData = {
		'zip': window.modForm.opts.getVars.zip ? window.modForm.opts.getVars.zip : window.modForm.opts.zipCode,
		'service': service,
		'site': window.modForm.opts.quadLinkData.WebSiteName?.replace(/^www\./, '').replace(/^qa\./, ''),
		...quadLinkData,
		...partnerSourceID,
		'serviceSpecificAttributes': {
			...window.modForm.opts.serviceSpecificAttributes
		},
		'searchContext': {
			'searchContext': 'O&O Web Pre Submit',
			'landingPageUrl': window.location.href.split('#')[0],
			...vwo
		}
	};

	if (JSON.stringify(consentSearchData) === window.modForm.opts.consentCaptureDetails) {
		return;
	}

	window.modForm.opts.consentCaptureDetails = JSON.stringify(consentSearchData);

	let response = {};
	let tempData = [];
	let isAdoptClient = false;
	const request = new XMLHttpRequest();
	request.open('POST', apiUrl);
	request.setRequestHeader('Content-Type', 'application/json');
	request.send(JSON.stringify(consentSearchData));
	request.addEventListener('load', function() {
		if (this.response) {
			response = { ...response, ...JSON.parse(this.response) };
			if (this.readyState === 4 && this.status === 200) {
				if (response.data.length === 1 && response.data[0].vendorDisplayName === 'Modernize') {
					// If Modernize is the only response – remove the "Providers" section
					if (providersWrapper) {
						providersWrapper.style.display = 'none';
					}
				} else {
					if (providersWrapper && providersWrapper.style.display === 'none') {
						providersWrapper.style.display = 'inline';
					}
				}

				if (response.requestId) {
					window.modForm.opts.consentRequestId = response.requestId;
				}

				if (JSON.stringify(response)) {
					if (targetDiv.hasChildNodes()) {
						while (targetDiv.firstChild) {
							targetDiv.removeChild(targetDiv.firstChild);
						}
					}

					// if exclusive vendor is the only vendor returned along with modernize, we need to change the tcpa language
					if (response.data && response.data.length === 2) {
						const exclusiveVendors = Object.values(window.modForm.opts.eCommerceVendorKeys.exclusive);
						for (let i = 0; i < 2; i++) {
							if (exclusiveVendors.includes(response.data[i].vendorKey.toString())) {
								modifyTcpaForExclusiveVendors(response.data[i]);
								targetDiv = window.modForm.opts.form.querySelector('[data-providers-consent]');
								window.Footer.initModalLinks('.tcpa a[data-bind="modal-page"]');
								isExclusiveMatch = true;
								window.exclusiveTcpa = true;
							}
						}
					}
					// When exclusive tcpa is displayed, and if user decides to change form data which doesn't return exclusive anymore
					if (!isExclusiveMatch && window.exclusiveTcpa) {
						window.modForm.opts.tcpaCopy[0].innerHTML = window.modForm.opts.originalTcpa;
						targetDiv = window.modForm.opts.form.querySelector('[data-providers-consent]');
						window.Footer.initModalLinks('.tcpa a[data-bind="modal-page"]');
					}

					if (response.data && Array.isArray(response.data)) {
						tempData = response.data;
						tempData.forEach(async function(data) {
							if (data.externalClientDetails && data.externalClientDetails.length) {
								await data.externalClientDetails.forEach((details, index) => {
									if (data.vendorKey === window.modForm.opts.eCommerceVendorKeys.consent.adopt) {
										isAdoptClient = true;
										new Promise(function(resolve) {
											getAdoptExternalClientNames(data.clientPingToken, details?.id);
											document.addEventListener('providerNameLoaded', function(e) {
												if (e.detail.status !== 'error') {
													const clientName = document.getElementById(details?.id)?.innerText;
													if (clientName) {
														details.name = clientName;
														updateConsentResponse(tempData, data, details);
													}
												} else {
													console.error(e.detail);
												}

												// Adopt dispatches 'providerNameLoaded' event for each client so need to resolve this Promise only once after getting all the client names.
												if (data.externalClientDetails.length - 1 === index) {
													resolve();
												}
											});
										}).then(function() {
											const ev = new Event('adoptClientsLoaded');
											document.dispatchEvent(ev);
										});
									} else {
										updateConsentResponse(tempData, data, details);
									}
								});
							}
						});
					}

					if (isAdoptClient) {
						document.addEventListener('adoptClientsLoaded', function() {
							window.modUtils.renderConsentCheckboxes(tempData, targetDiv, window.modForm.opts.fetchAndFormatConsentListings || window.modForm.opts.formatConsentCaptureVendors);

							updateConsentHiddenFields();

							if (callback) {
								callback(response);
							}

						}, { once: true });
					} else {
						window.modUtils.renderConsentCheckboxes(tempData, targetDiv, window.modForm.opts.fetchAndFormatConsentListings || window.modForm.opts.formatConsentCaptureVendors);

						updateConsentHiddenFields();

						if (callback) {
							callback(response);
						}
					}
				}
			}
		}
	});

	request.addEventListener('error', function() {
		console.error('Something went wrong!');
	});
}
