import { getEnv } from '../url';


/**
 * Initialize the Sentry error monitoring and tracking with provided publicKey.
 * This function is typically used to set up Sentry for logging application errors, crashes or performance data.
 *
 * @export
 * @param {string} [publicKey=''] - The publicKey of the project loader file.
 * @param {string[]} [env=['production']] - Array of environments where the function should be executed.
 * @example
 * // Initialize Sentry with a file hash
 * initSentry('abc987123');
 * @link https://docs.sentry.io/platforms/javascript/install/loader/
 * @returns {void}
 */
export default function initSentry(publicKey = '', env = ['production']) {
	if (!env.includes(getEnv()) || !publicKey || typeof publicKey !== 'string') {
		return false;
	}

	const sentryScript = document.createElement('script');
	sentryScript.src = `https://js.sentry-cdn.com/${publicKey}.min.js`;
	sentryScript.crossOrigin = 'anonymous';
	sentryScript.defer = true;
	document.head.append(sentryScript);
}
