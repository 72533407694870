
/**
 * This function loads adopt script to get their client names. For this it requires clientPingToken, providerId and the HTML element id where we want to show the client name.
 *
 * Here we creating a hidden span tag and loading the adopt script for each external clients. Once script loads the client name in the created span tag then we will read that name and place it into the external clients object in getConsentListing() so that renderConsentCheckboxes() method will show the name with the checkbox.
 *
 * @export
 * @param {*} clientPingToken -
 * @param {*} providerId -
 */
export function getAdoptExternalClientNames(clientPingToken, providerId) {
	const tcpaCopy = window.modForm.opts.tcpaCopy[0];
	if (!document.getElementById(providerId)) {
		const adoptClientEl = document.createElement('span');
		adoptClientEl.setAttribute('id', providerId);
		adoptClientEl.style.display = 'none';
		tcpaCopy.insertAdjacentElement('afterBegin', adoptClientEl);
	}
	const adoptScript = document.createElement('script');
	adoptScript.src = `https://enduser-api.com/getcustomer.js?token=${clientPingToken}&providerId=${providerId}&elementId=${providerId}`;
	document.body.appendChild(adoptScript);
}
